import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

const TagRoute = (props) =>  {

    const posts = props.data.allMarkdownRemark.edges;

    const postLinks = posts.map((post) => (
      <li key={post.node.fields.slug} className="p-6 bg-white border  hover:shadow-lg hover:border-white rounded-xl">
        <Link to={post.node.fields.slug}>
          <h2 className="font-medium capitalize text-lg">{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ));

    const { tag } = props.pageContext;
    const { title } = props.data.site.siteMetadata;
    const { totalCount } = props.data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`;
    const theTag = tag;

    return (
      <Layout>
        <section className="section pt-48 pb-24">
          <Helmet title={`${tag} | ${title}`} />
          <div className="max-w-3xl mx-auto">
            <div >
                <div className="flex justify-between items-end mb-12">
                  <h1 className="text-5xl font-semibold block">Blog Tags</h1>
                  <h3 className=" text-xl block">{tagHeader}</h3>
                </div>
                
                <ul className="grid grid-cols-1 gap-4">{postLinks}</ul>
                <div className="mt-24">
                  <Link to="/tags/" className="text-xl">Browse all tags</Link>
                </div>
   
            </div>
          </div>
        </section>
      </Layout>
    );
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
